/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonPrimary, FilterAtom, theme } from "c4u-web-components";
import { differenceInDays } from "date-fns";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  PackHelper,
  PaymentMethodHelper,
  PaymentStatusHelper,
} from "../../../helper";
import { useSessionContext } from "../../../hooks";
import {
  GetPurchaseOrderRequest,
  IGetPurchaseOrderRequest,
  IGetPurchaseOrderResponse,
  IPagedResponseBase,
  IPurchaseOrderPagedResponse,
} from "../../../models";
import { FormikControlRenaveAtom } from "../../atoms";
import {
  CleanFiltersDiv,
  MenuIconsWrapper,
  WrapperButtons,
  WrapperFilterIsOpen,
  WrapperSearch,
} from "./purchase-order-search-form.molecule.style";

interface IProps {
  getPurchaseOrder: (
    request: GetPurchaseOrderRequest
  ) => Promise<IPurchaseOrderPagedResponse>;
  setPaginatedPurchaseOrder: (
    v: IGetPurchaseOrderResponse[] | undefined
  ) => void;
  paginationData?: IPagedResponseBase;
  setPaginationData: (v: IPagedResponseBase | undefined) => void;
  setPageNumber: (v: number, isFilter?: boolean) => void;
  setFilterValues?: (v: IGetPurchaseOrderRequest | undefined) => void;
  initialValues: IGetPurchaseOrderRequest;
}

export const PurchaseOrderSearchFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { showGenericErrorModal } = useSessionContext();

  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [resetValidation, setResetValidation] = useState(false);

  const validations = Yup.object<IGetPurchaseOrderRequest>({
    startDate: Yup.date()
      .required(t("TheStartDateCannotBeEmpty"))
      .test("date-start-small-date-final", " ", function (end) {
        if (!end) return true;
        const start = this.resolve(Yup.ref("finishDate"));
        const diffDays = differenceInDays(start, end);

        return diffDays >= 0;
      })
      .test("period-valid", " ", function (end) {
        if (!end) return true;
        const start = this.resolve(Yup.ref("finishDate"));
        const diffDays = differenceInDays(start, end);

        return diffDays <= 90;
      }),
    finishDate: Yup.date()
      .required(t("TheFinalDateCannotBeEmpty"))
      .test("date-start-small-date-final", t("ValidationDate"), function (end) {
        if (!end) return true;
        const start = this.resolve(Yup.ref("startDate"));
        const diffDays = differenceInDays(end, start);

        return diffDays >= 0;
      })
      .test("period-valid", t("ValidationIntervalDates"), function (end) {
        if (!end) return true;
        const start = this.resolve(Yup.ref("startDate"));
        const diffDays = differenceInDays(end, start);

        return diffDays <= 90;
      }),
    page: Yup.number().required(),
    pageSize: Yup.number().required(),
  });

  const formik = useFormik<IGetPurchaseOrderRequest>({
    initialValues: props.initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        const { data, ...paginationData } = await props.getPurchaseOrder(
          new GetPurchaseOrderRequest({ ...values, page: 1 })
        );

        props.setPaginatedPurchaseOrder(data);
        props.setPaginationData(paginationData);
        if (props.setFilterValues) props.setFilterValues(values);
        if (props.setPageNumber) props.setPageNumber(1, true);
        formik.setFieldValue("page", 1);
      } catch (e) {
        props.setPaginatedPurchaseOrder([]);
        showGenericErrorModal(
          t("ErrorMsgGetCreditStatement"),
          null,
          t("Error")
        );
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    if (resetValidation) {
      formik.submitForm();
      setResetValidation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetValidation]);

  const handleCleanFilters = useCallback(() => {
    formik.resetForm();
    formik.setValues(props.initialValues);
    setResetValidation(true);
  }, [formik, props.initialValues]);

  const fieldProps = useMemo(() => {
    return {
      formik,
      translate: t,
      xs: 6,
      sm: 4,
      md: 3,
    };
  }, [formik, t]);

  return (
    <Form className="mt-4" onSubmit={formik.handleSubmit}>
      <Col>
        <WrapperSearch>
          <FormikControlRenaveAtom
            {...fieldProps}
            property="startDate"
            label={t("StartDate")}
            type="date"
          />

          <FormikControlRenaveAtom
            {...fieldProps}
            property="finishDate"
            label={t("FinalDate")}
            type="date"
          />

          <MenuIconsWrapper>
            <ButtonPrimary
              type="submit"
              sizex="sm"
              sizey="thin"
              loading={formik.isSubmitting}
            >
              {t("OK")}
            </ButtonPrimary>
            <FilterAtom
              filterText={t("Filter")}
              filterIsOpen={filterIsOpen}
              setFilterIsOpen={setFilterIsOpen}
              color={theme.colors.BlueNavy}
              hasHoverEffect={true}
            />
          </MenuIconsWrapper>
        </WrapperSearch>
        {filterIsOpen && (
          <>
            <WrapperFilterIsOpen>
              <FormikControlRenaveAtom
                type={"select"}
                label={t("PurchaseType")}
                property={"purchaseType"}
                formik={formik}
                placeholder={t("AllStatus")}
                sm={2}
                lg={3}
                data={PackHelper.getStatusCombos(t)}
              />
              <FormikControlRenaveAtom
                type={"select"}
                label={t("Pack")}
                property={"pack"}
                formik={formik}
                placeholder={t("AllStatus")}
                sm={2}
                lg={2}
                data={PackHelper.getPacksCombos()}
              />
              <FormikControlRenaveAtom
                type={"select"}
                label={t("PaymentMethod")}
                property={"paymentMethod"}
                formik={formik}
                placeholder={t("AllStatus")}
                sm={2}
                lg={3}
                data={PaymentMethodHelper.getStatusCombos(t)}
              />
              <FormikControlRenaveAtom
                type={"select"}
                label={t("StatusPayment")}
                property={"status"}
                formik={formik}
                placeholder={t("AllStatus")}
                sm={2}
                lg={2}
                data={PaymentStatusHelper.getStatusCombos(t)}
              />

              <WrapperButtons>
                <CleanFiltersDiv
                  onClick={formik.isSubmitting ? undefined : handleCleanFilters}
                >
                  {t("CleanFilters")}
                </CleanFiltersDiv>
              </WrapperButtons>
            </WrapperFilterIsOpen>
          </>
        )}
      </Col>
    </Form>
  );
};
