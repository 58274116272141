import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
  CoxIcon,
} from "c4u-web-components";
import React, { useCallback, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  Content,
  ContentModalMsg,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Title,
  WrapperButtonsConfirm,
} from "./feedback-modal.molecule.style";

interface IProps {
  typeIcon?: "none" | "success" | "error" | "warning";
  typeButton?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "confirm-cancel"
    | "confirm-yesno";
  show: boolean;
  onHide: () => void;
  onClickButton?: () => void;
  title?: string;
  titleHeader?: string;
  textButton?: string;
  message?: string;
  customButton?: React.ReactNode;
  size?: "sm" | "md" | "lg" | "xl";
  buttonSizeX?: "sm" | "md" | "lg";
  buttonSizeY?: "thin" | "normal";
  hideCloseButton?: boolean;
  isSubmitting?: boolean;
  hideFooter?: boolean;
  reducePaddingTop?: boolean;
  dataCy?: string;
}

export const FeedbackModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const {
    typeIcon,
    typeButton,
    buttonSizeX,
    buttonSizeY,
    isSubmitting,
    onClickButton,
    onHide,
    textButton,
  } = props;

  const iconName = useMemo<string | null>(() => {
    switch (typeIcon) {
      case "none":
        return null;
      case "error":
        return "danger-color";
      case "warning":
        return "warning-color";
      case "success":
      default:
        return "checked";
    }
  }, [typeIcon]);

  const StandardButton = useMemo(() => {
    switch (typeButton) {
      case "primary":
        return ButtonPrimary;
      case "secondary":
        return ButtonSecondary;
      case "tertiary":
      default:
        return ButtonTertiary;
    }
  }, [typeButton]);

  const isSingleButton = useMemo(
    () =>
      typeButton === undefined ||
      ["primary", "secondary", "tertiary"].includes(typeButton),
    [typeButton]
  );

  const getOtherButtons = useCallback(() => {
    let leftDefaultText, rightDefaultText;
    switch (typeButton) {
      case "confirm-cancel":
        leftDefaultText = t("Cancel");
        rightDefaultText = t("Ok");
        break;
      case "confirm-yesno":
      default:
        leftDefaultText = t("No");
        rightDefaultText = t("Yes");
        break;
    }

    return (
      <WrapperButtonsConfirm>
        <ButtonSecondary
          onClick={onHide}
          sizex={buttonSizeX ?? "md"}
          sizey={buttonSizeY ?? "normal"}
          disabled={isSubmitting}
        >
          {leftDefaultText}
        </ButtonSecondary>
        <ButtonTertiary
          onClick={onClickButton ? onClickButton : onHide}
          sizex={buttonSizeX ?? "md"}
          sizey={buttonSizeY ?? "normal"}
          loading={isSubmitting}
        >
          {textButton ? textButton : rightDefaultText}
        </ButtonTertiary>
      </WrapperButtonsConfirm>
    );
  }, [
    buttonSizeX,
    buttonSizeY,
    isSubmitting,
    onClickButton,
    onHide,
    textButton,
    t,
    typeButton,
  ]);

  return (
    <Modal
      show={props.show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.onHide}
      size={props.size === "md" ? undefined : props.size ?? "lg"}
      data-cyId={props.dataCy}
    >
      <ModalHeader closeButton={!props.hideCloseButton}>
        {props.titleHeader}
      </ModalHeader>

      <ModalBody
        className={props.reducePaddingTop ? "reduce-padding-top" : ""}
        dataCy={props.dataCy}
      >
        {!!iconName && (
          <Icon>
            <CoxIcon name={iconName} width={32} height={32} />
          </Icon>
        )}
        <Title>{props.title}</Title>
        <Content>
          <ContentModalMsg>{props.message}</ContentModalMsg>
          {props.children}
        </Content>
      </ModalBody>

      {!props.hideFooter && (
        <ModalFooter>
          {!!props.customButton ? (
            props.customButton
          ) : isSingleButton ? (
            <StandardButton
              onClick={props.onClickButton ? props.onClickButton : props.onHide}
              sizex={"md"}
              loading={isSubmitting}
            >
              {props.textButton ? props.textButton : t("OK")}
            </StandardButton>
          ) : (
            getOtherButtons()
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};
