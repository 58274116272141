import { Form } from "react-bootstrap";
import styled from "styled-components";

export const ModalBodyFormRow = styled(Form.Row)`
  margin: 0px 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .col {
    padding: 0;
  }

  span {
    font-weight: 700;
    font-size: 20px;
    color: rgb(31, 62, 116);
    text-align: center;
  }
`;

export const ActionButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
