import React from "react";
import {
  CardContent,
  CardTitle,
  ContentCardWrapper,
} from "./content-card.molecule.style";

interface IProps {
  title: string;
  id?: string;
}

export const ContentCardMolecule: React.FC<IProps> = (props) => {
  return (
    <ContentCardWrapper id={props.id}>
      <CardTitle>{props.title}</CardTitle>
      <hr />
      <CardContent>{props.children}</CardContent>
    </ContentCardWrapper>
  );
};
