import { ButtonSecondary, ButtonTertiary } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { FeedbackModal } from "../../feedback-modal/feedback-modal.molecule";

import {
  ActionButtonsGroup,
  ModalBodyFormRow,
} from "./confimation-modal.molecule.style";

interface IProps {
  isModalVisible: boolean;
  setIsModalVisible: (v: boolean) => void;
  onAction: () => void;
  messageBody: string
}

export const ConfirmationModalMolecule: React.FC<IProps> = ({
  isModalVisible,
  setIsModalVisible,
  onAction,
  messageBody,
}) => {
  const { t } = useTranslation();

  const onHide = () => {
    setIsModalVisible(false);
  };

  return (
    <FeedbackModal
      show={isModalVisible}
      onHide={onHide}
      typeIcon="warning"
      size="md"
      hideFooter
      reducePaddingTop
    >
      <ModalBodyFormRow>
        <span>{messageBody}</span>
      </ModalBodyFormRow>

      <ActionButtonsGroup>
        <ButtonSecondary sizex={"md"} onClick={onHide}>
          {t("No")}
        </ButtonSecondary>
        <ButtonTertiary sizex={"md"} onClick={onAction}>
          {t("Yes")}
        </ButtonTertiary>
      </ActionButtonsGroup>
    </FeedbackModal>
  );
};
