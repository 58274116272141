import React from "react";
import { WrapperUpload } from "./upload-loading.atom.style";
import { CoxIcon } from "c4u-web-components";
import { DateHelper } from "c4u-web-components";

interface IProps {
  content: string;
  date: Date;
}

export const UploadLoadingAtom: React.FC<IProps> = ({ content, date }) => {
  return (
    <>
      <WrapperUpload>
        <CoxIcon name="reload" width={15} height={15} fillColor="#A8AAAC" />
        <span>
          {content} {DateHelper.dateFormat(date)}
          {" às "}
          {DateHelper.formatTime(date)}
        </span>
      </WrapperUpload>
    </>
  );
};
