import { ButtonTertiary } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { useRenave } from "../../../hooks";
import {
  GetDocumentRequest,
  IDownloadDocumentModel,
  TypeDocumentCodeEnum,
} from "../../../models";
import { CardDownloadDocumentMolecule } from "../../molecules/card-download-document/card-download-document.molecule";
import {
  ButtonWrapper,
  ManageVehicleContentWrapper,
} from "../shared-style/organisms.shared-style";

interface IProps {
  viewMode?: boolean;
}


export const ZeroKmVehicleCheckedOutOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { getDocument } = useRenave();
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const downloadDocuments: IDownloadDocumentModel[] = [
    {
      title: t("ExitAtvp"),
      icon: "checked",
      text: t("DocumentGeneratedIn"),
      typeDocumentCode: TypeDocumentCodeEnum.ExitATPVZeroKm,
    },
  ];

  return (
    <>
      <ManageVehicleContentWrapper>
        {downloadDocuments.map((m, i) => (
          <CardDownloadDocumentMolecule
            key={`download-doc-card-${i}`}
            iconName={m.icon}
            title={m.title}
            text={m.text}
            onClickGetDocument={() =>
              getDocument(
                new GetDocumentRequest({
                  id: Number(params.id),
                  typeDocumentCode: m.typeDocumentCode,
                })
              )
            }
          />
        ))}
      </ManageVehicleContentWrapper>

      <ButtonWrapper>
        <ButtonTertiary
          sizex="md"
          onClick={() => {
            history.push(paths.home());
          }}
          disabled={props.viewMode}
        >
          {t("Finish")}
        </ButtonTertiary>
      </ButtonWrapper>
    </>
  );
};
