import {
  ButtonSecondary,
  ButtonTertiary,
  SpinnerCenteredAtom,
  TypesHelper,
} from "c4u-web-components";
import jsPDF from "jspdf";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { useRenave, useRenaveContext, useSessionContext } from "../../../hooks";
import {
  GetDocumentRequest,
  IDocumentModel,
  NotifySignatureRequest,
  PostEntryCancellationRequest,
  RenaveMenuStepsEnum,
  TypeDocumentCodeEnum,
  TypeIndexDocumentEnum,
  TypeMethodSendingAtpveEnum,
  UploadDocumentRequest,
} from "../../../models";
import { Show } from "../../atoms";
import {
  ConfirmationModalMolecule,
  ContentCardMolecule,
  UploadDocumentMolecule,
} from "../../molecules";
import { ButtonWrapper } from "../shared-style/organisms.shared-style";
interface ImageDimension {
  width: number;
  height: number;
}

interface IProps {
  isSubmitting: boolean;
  setIsSubmittingDocument: (v: boolean) => void;
  isSubmittingDocument: boolean;
  formValid: boolean;
  setEnableSendDocument: (v: boolean) => void;
  disableAll?: boolean;
  isAdvancedAtpv?: boolean;
}

export const CheckInVehicleDocumentOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const {
    setIsSubmittingDocument,
    isSubmittingDocument,
    isSubmitting,
    formValid,
  } = props;
  const {
    uploadDocument,
    getDocument,
    postNotifySignature,
    postEntryCancellation,
  } = useRenave();
  const {
    documentContext,
    setDocumentContext,
    vehicleEntryContext,
    restrictionContext,

    setMenuCurrentStepContext,
  } = useRenaveContext();
  const {
    showGenericWarningModal,
    showGenericErrorModal,
    showGenericSuccessModal,
  } = useSessionContext();
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const [isValidDocument, setValidDocument] = useState<boolean>();
  const [isGetDocument, setIsGetDocument] = useState<boolean>(false);
  const [isSubmittingCancelEntry, setIsSubmittingCancelEntry] = useState(false);
  const [isOpenConfimationModal, setIsOpenConfimationModal] = useState(false);

  const A4_PAPER_DIMENSIONS = {
    width: 210,
    height: 297,
  };

  const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

  const imageDimensionsOnA4 = (dimensions: ImageDimension) => {
    const isLandscapeImage = dimensions.width >= dimensions.height;

    if (isLandscapeImage) {
      return {
        width: A4_PAPER_DIMENSIONS.width,
        height:
          A4_PAPER_DIMENSIONS.width / (dimensions.width / dimensions.height),
      };
    }

    const imageRatio = dimensions.width / dimensions.height;
    if (imageRatio > A4_PAPER_RATIO) {
      const imageScaleFactor =
        (A4_PAPER_RATIO * dimensions.height) / dimensions.width;

      const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

      return {
        height: scaledImageHeight,
        width: scaledImageHeight * imageRatio,
      };
    }

    return {
      width:
        A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
      height: A4_PAPER_DIMENSIONS.height,
    };
  };

  const generatePdf = async () => {
    if (!isValidDocument) {
      if (
        TypesHelper.toNumberNull(documentContext.typeMethodSending) ===
        TypeMethodSendingAtpveEnum.FrontAndBackFiles
      ) {
        if (documentContext?.file.length === 2) {
          const doc = new jsPDF();

          doc.deletePage(1);

          documentContext.file.forEach((image) => {
            const imageDimensions = imageDimensionsOnA4({
              width: image.width ?? 0,
              height: image.height ?? 0,
            });

            doc.addPage();
            doc.addImage(
              URL.createObjectURL(image.file),
              image.file.type,
              (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
              (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
              imageDimensions.width,
              imageDimensions.height
            );
          });

          const pdfBlob = doc.output("blob");
          const file = new File([pdfBlob], "pdf-file", {
            type: pdfBlob.type,
          });

          await uploadDocumentAsync(file);
        } else {
          setIsSubmittingDocument(false);
          showGenericWarningModal(
            t("InsertImagesCorrectly"),
            null,
            t("Attention")
          );
        }
      } else {
        if (
          documentContext?.file.length === 1 &&
          documentContext?.file[0].index === TypeIndexDocumentEnum.PdfDocument
        ) {
          const fileBlob = documentContext?.file[0].file;

          const file = new File([fileBlob], "pdf-file", {
            type: fileBlob.type,
          });

          await uploadDocumentAsync(file);
        } else {
          setIsSubmittingDocument(false);
          showGenericWarningModal(
            t("InsertDocumentCorrectly"),
            null,
            t("Attention")
          );
        }
      }
    } else {
      updateStep();
    }
  };

  const uploadDocumentAsync = async (filePdf: File | null) => {
    try {
      if (filePdf === null && props.isAdvancedAtpv) {
        const request = new NotifySignatureRequest({
          plate: restrictionContext.vehicle.plate,
          numberCrv: restrictionContext.vehicle.numberCrv,
          renavam: restrictionContext.vehicle.renavam,
          typeCrv: restrictionContext.vehicle.typeCrv,
          typeSignature: vehicleEntryContext.typeSignature,
        });
        await postNotifySignature(request);
      } else {
        const request = new UploadDocumentRequest({
          file: filePdf,
          plate: restrictionContext.vehicle.plate,
          numberCrv: restrictionContext.vehicle.numberCrv,
          renavam: restrictionContext.vehicle.renavam,
          typeCrv: restrictionContext.vehicle.typeCrv,
          typeSignature: vehicleEntryContext.typeSignature,
        });
        await uploadDocument(request);
      }
      updateStep();
    } catch (error) {
      setValidDocument(false);
      setIsSubmittingDocument(false);
      showGenericErrorModal(
        props.isAdvancedAtpv
          ? t("AdvancedSignatureError")
          : t("GenericErrorMsg"),
        null,
        t("Error")
      );
    }
  };

  const getDocumentAsync = useCallback(async () => {
    let data = await getDocument(
      new GetDocumentRequest({
        id: Number(params.id),
        typeDocumentCode: TypeDocumentCodeEnum.ATPV,
      })
    );

    var valuesContext = documentContext;

    valuesContext = {
      typeMethodSending: TypeMethodSendingAtpveEnum.UniqueFile,
      file: [],
    } as IDocumentModel;

    if (data !== undefined) {
      const base64Response = await fetch(
        `data:application/pdf;base64,${data.base64File}`
      );
      const blob = await base64Response.blob();
      const file = new File([blob], "pdf-file", {
        type: blob.type,
      });

      valuesContext.typeMethodSending = TypeMethodSendingAtpveEnum.UniqueFile;
      valuesContext.file = [];
      valuesContext.file.push({
        file: file,
        title: file.name,
        index: 3,
      });

      setValidDocument(true);
    }

    setDocumentContext(valuesContext);
  }, [getDocument, documentContext, setDocumentContext, params.id]);

  const handleEntryCancellation = async (id: number) => {
    try {
      await postEntryCancellation(new PostEntryCancellationRequest({ id }));

      showGenericSuccessModal(
        "",
        () => history.push(paths.myStock()),
        t("CancellationOfEntrySuccessful")
      );
    } catch (e) {
      showGenericErrorModal(t("CancelEntryError"), null, "", t("Danfe"));
    } finally {
      setIsSubmittingCancelEntry(false);
    }
  };

  const updateStep = useCallback(() => {
    setValidDocument(true);
    setIsSubmittingDocument(false);
    setMenuCurrentStepContext(RenaveMenuStepsEnum.CheckInDanfe);
    history.push(paths.checkInDanfe(params.id));
  }, [history, params.id, setIsSubmittingDocument, setMenuCurrentStepContext]);

  useEffect(() => {
    if (
      props.disableAll &&
      props.isAdvancedAtpv !== undefined &&
      !props.isAdvancedAtpv
    ) {
      getDocumentAsync().finally(() => {
        setIsGetDocument(true);
      });
    } else {
      setIsGetDocument(true);
    }
  }, [props.disableAll, props.isAdvancedAtpv]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ContentCardMolecule title={t("SendAtpve")}>
        <Show when={isGetDocument} fallback={<SpinnerCenteredAtom />}>
          {!props.isAdvancedAtpv ? (
            <UploadDocumentMolecule
              typeMethodSendingAtpve={
                documentContext?.typeMethodSending ??
                TypeMethodSendingAtpveEnum.UniqueFile
              }
              isSubmitting={
                isSubmittingDocument ? isSubmittingDocument : isSubmitting
              }
              setValidDocument={setValidDocument}
              typeSignature={vehicleEntryContext?.typeSignature}
              disableAll={props.disableAll}
            />
          ) : (
            <>
              <p>{t("VehicleEntrySuccessMessage")}</p>
              <span>{t("AdvancedSignatureMessage")}</span>
            </>
          )}
        </Show>
      </ContentCardMolecule>

      <ButtonWrapper className="no-top-margin">
        <ButtonSecondary
          sizex="md"
          loading={isSubmitting}
          onClick={() => {
            setIsOpenConfimationModal(true);
          }}
          disabled={
            !formValid ||
            isSubmittingDocument ||
            isSubmittingCancelEntry ||
            props.disableAll
          }
        >
          {t("CancelEntry")}
        </ButtonSecondary>

        <ButtonTertiary
          sizex="md"
          loading={isSubmittingDocument}
          onClick={() => {
            setIsSubmittingDocument(true);
            props.isAdvancedAtpv ? uploadDocumentAsync(null) : generatePdf();
          }}
          disabled={isSubmitting || props.disableAll}
        >
          {props.isAdvancedAtpv ? t("NotifySignature") : t("Send Document")}
        </ButtonTertiary>
      </ButtonWrapper>

      {isOpenConfimationModal && (
        <ConfirmationModalMolecule
          isModalVisible={isOpenConfimationModal}
          setIsModalVisible={(value) => setIsOpenConfimationModal(value)}
          onAction={() => handleEntryCancellation(Number(params.id))}
          messageBody={t("DoYouReallyWantToCancel")}
        />
      )}
    </>
  );
};
