export interface IGetSecurityCodeCrvResquest {
  id: number;
}

export class GetSecurityCodeCrvResquest {
  id: number;
  constructor({ id }: IGetSecurityCodeCrvResquest) {
    this.id = id;
  }
}
