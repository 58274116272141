import { TypesHelper } from "c4u-web-components";
import { IGetRestrictionRequest, IVehicleDocumentData } from "../..";

export interface IPostVehicleRequest extends IGetRestrictionRequest {
  kbbId?: number;
  brand?: string;
  model?: string;
  version?: string;
  modelYear?: number;
  molicarId?: string;
}

export class PostVehicleRequest {
  plate: string | null;
  renavam: string | null;
  numberCrv: string | null;
  typeCrv: number | null;
  capable: boolean | null;
  kbbId: number | null;
  brand: string | null;
  model: string | null;
  version: string | null;
  modelYear: number | null;
  molicarId: string | null;

  constructor({
    plate,
    renavam,
    numberCrv,
    typeCrv,
    capable,
    kbbId,
    brand,
    model,
    version,
    modelYear,
    molicarId
  }: IPostVehicleRequest) {
    this.plate = TypesHelper.toStringNull(plate.replace("-", ""));
    this.renavam = TypesHelper.toStringOnlyNumbersNull(renavam);
    this.numberCrv = TypesHelper.toStringOnlyNumbersNull(numberCrv);
    this.typeCrv = TypesHelper.toNumberNull(typeCrv);
    this.capable = TypesHelper.toBooleanNull(capable);
    this.kbbId = TypesHelper.toNumberNull(kbbId)
    this.brand = TypesHelper.toStringNull(brand)
    this.model = TypesHelper.toStringNull(model)
    this.version = TypesHelper.toStringNull(version)
    this.modelYear = TypesHelper.toNumberNull(modelYear)
    this.molicarId = TypesHelper.toStringNull(molicarId)
  }
}

export interface IPostVehicleResponse extends IVehicleDocumentData {
  id: number;
  dealershipBusinessNumber?: string;
}
