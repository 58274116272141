/* eslint-disable react-hooks/exhaustive-deps */
import { DateHelper, SpinnerCenteredAtom } from "c4u-web-components";
import { addDays } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useRenave } from "../../../hooks";
import {
  GetPurchaseOrderRequest,
  IGetPurchaseOrderRequest,
  IGetPurchaseOrderResponse,
  IPagedResponseBase
} from "../../../models";
import {
  PaginationControlMolecule,
  PurchaseOrderHeaderMolecule,
  PurchaseOrderListMolecule,
  PurchaseOrderSearchFormMolecule,
} from "../../molecules";
import {
  OrderListPaginationWrapper,
  OrderListWrapper,
} from "./purchase-order-list.organism.style";

export const PurchaseOrderOrganism: React.FC = () => {
  const initialValues = useMemo<IGetPurchaseOrderRequest>(() => {
    const dateNow = new Date();

    return {
      startDate: DateHelper.dateHourCustomFormat(
        addDays(dateNow, -7),
        "yyyy-MM-dd"
      ),
      finishDate: DateHelper.dateHourCustomFormat(dateNow, "yyyy-MM-dd"),
      purchaseType: "",
      pack: "",
      paymentMethod: "",
      status: "",
      page: 1,
      pageSize: 10,
    };
  }, []);

  const { purchaseOrdersList } = useRenave();

  const [paginationData, setPaginationData] = useState<IPagedResponseBase>();
  const [pageNumber, setPageNumber] = useState<{
    number: number;
    isFilter: boolean;
  }>();
  const [paginatedPurchaseOrder, setPaginatedPurchaseOrder] = useState<
    IGetPurchaseOrderResponse[]
  >();
  const [goUpdate, setGoUpdate] = useState(false);
  const [filterValues, setFilterValues] = useState<IGetPurchaseOrderRequest>();

  useEffect(() => {
    const validationFilter = async () => {
      if (filterValues && pageNumber && !pageNumber.isFilter) {
        const request = new GetPurchaseOrderRequest({
          ...filterValues,
          page: pageNumber.number,
        });

        const { data, ...paginationData } = await purchaseOrdersList(request);
        setPaginatedPurchaseOrder(data || []);
        setPaginationData(paginationData);
      }
    };
    validationFilter();
  }, [pageNumber]);

  useEffect(() => {
    async function firstRender() {
      const request = new GetPurchaseOrderRequest({
        ...initialValues,
      });

      const { data, ...paginationData } = await purchaseOrdersList(request);
      setPaginatedPurchaseOrder(data || []);
      setFilterValues(initialValues);
      setPaginationData(paginationData);
    }
    firstRender();
  }, []);

  const handlePageNumberChange = (
    number: number,
    isFilter: boolean = false
  ) => {
    setPageNumber({
      number,
      isFilter,
    });
  };

  useEffect(() => {
    if (goUpdate) setGoUpdate(false);
  }, [goUpdate]);

  return (
    <OrderListWrapper>
      <Row>
        <Col lg={7} xl={9}>
          <PurchaseOrderSearchFormMolecule
            getPurchaseOrder={purchaseOrdersList}
            setPaginatedPurchaseOrder={(value) =>
              setPaginatedPurchaseOrder(value)
            }
            setPageNumber={handlePageNumberChange}
            paginationData={paginationData}
            initialValues={initialValues}
            setPaginationData={setPaginationData}
            setFilterValues={(value) => setFilterValues(value)}
          />
        </Col>
      </Row>

      <div className="mt-5">
        <PurchaseOrderHeaderMolecule />
      </div>

      {paginatedPurchaseOrder === undefined ? (
        <SpinnerCenteredAtom classNames="mt-5" />
      ) : !paginatedPurchaseOrder.length ? (
        <PurchaseOrderListMolecule purchaseOrderData={undefined} />
      ) : (
        <>
          {paginatedPurchaseOrder.map((m, i) => (
            <PurchaseOrderListMolecule
              key={`item-purchase-order=${i}`}
              purchaseOrderData={m}
            />
          ))}

          {paginationData && paginationData.totalPages > 1 && (
            <OrderListPaginationWrapper>
              <PaginationControlMolecule
                paginationData={paginationData}
                setPageNumber={handlePageNumberChange}
              />
            </OrderListPaginationWrapper>
          )}
        </>
      )}
    </OrderListWrapper>
  );
};
