import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
  SpinnerCenteredAtom,
} from "c4u-web-components";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../../constants";
import { RenaveStatusHelper } from "../../../../helper";
import { useRenave, useRenaveContext } from "../../../../hooks";
import {
  ICheckInZeroKmModel,
  IGetVehicleResponse,
  IPagedResponseBase,
  RenaveMenuStepsZeroKmEnum,
  RenaveStatusEnum,
  TypeDocumentCodeEnum,
} from "../../../../models";
import {
  CancelRejectionModalMolecule,
  DownloadDocumentMolecule,
  PaginationControlMolecule,
  RejectEntryModalMolecule,
  RenaveCardMolecule,
} from "../../../molecules";
import {
  MyStockListWrapper,
  MyStockPageWrapper,
  NoVehiclesFoundDiv,
} from "./my-stock-list.organism.style";

interface IProps {
  myVehicles?: IGetVehicleResponse[];
  paginationData?: IPagedResponseBase;
  setPageNumber: (v: number, isFilter?: boolean) => void;
  setGoUpdate: (v: boolean) => void;
  isUpdatingVehicles: boolean;
}

export const MyStockListOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { postRejectZeroKm, postCancelRejectionZeroKm } = useRenave();

  const {
    setMenuCurrentStepContext,
    setMenuCurrentStepZeroKmContext,
    setZeroKmVehicleEntryContext,
  } = useRenaveContext();

  const [rejectEntryId, setRejectEntryId] = useState<number>();
  const [cancelRejectionId, setCancelRejectionId] = useState<number>();

  const getButtons = useCallback(
    (id: number, status: RenaveStatusEnum, vin?: string) => {
      if (
        [
          RenaveStatusEnum.AptnessRestriction,
          RenaveStatusEnum.Aptness,
          RenaveStatusEnum.Cancelled,
          RenaveStatusEnum.ReturnedZeroKm,
        ].includes(status)
      )
        return undefined;

      const entryTermButton = (
        <DownloadDocumentMolecule
          id={id}
          title={t("EntryTermButtonText")}
          typeDocument={TypeDocumentCodeEnum.EntryTerm}
        />
      );

      switch (status) {
        case RenaveStatusEnum.PendingZeroKm:
          const buttonRejectEntry = (
            <ButtonSecondary
              sizex="sm"
              sizey="thin"
              onClick={() => setRejectEntryId(id)}
            >
              {t("Reject")}
            </ButtonSecondary>
          );
          const buttonAcceptEntry = (
            <ButtonPrimary
              sizex="sm"
              sizey="thin"
              onClick={() => {
                setMenuCurrentStepZeroKmContext(
                  RenaveMenuStepsZeroKmEnum.CheckInVehicleZeroKm
                );
                setZeroKmVehicleEntryContext({
                  vin,
                } as ICheckInZeroKmModel);
                push(paths.checkInZeroKm(id));
              }}
            >
              {t("Entry")}
            </ButtonPrimary>
          );
          return [buttonRejectEntry, buttonAcceptEntry];

        case RenaveStatusEnum.RejectedZeroKm:
          const buttonCancelRejection = (
            <ButtonTertiary
              sizex="md"
              sizey="thin"
              onClick={() => setCancelRejectionId(id)}
            >
              {t("CancelRejection")}
            </ButtonTertiary>
          );
          return [buttonCancelRejection];

        case RenaveStatusEnum.CheckInVehicle:
        case RenaveStatusEnum.CheckInDanfe:
          return [entryTermButton];

        case RenaveStatusEnum.CheckedIn:
        case RenaveStatusEnum.CheckOutVehicle:
        case RenaveStatusEnum.CheckOutDanfe:
          const crlvButton = (
            <DownloadDocumentMolecule
              id={id}
              title={t("CrlvButtonText")}
              typeDocument={TypeDocumentCodeEnum.CRLV}
            />
          );
          return [entryTermButton, crlvButton];

        case RenaveStatusEnum.CheckedOut:
          const exitTermButton = (
            <DownloadDocumentMolecule
              id={id}
              title={t("ExitTermButtonText")}
              typeDocument={TypeDocumentCodeEnum.ExitTerm}
            />
          );
          const exitAtpvButton = (
            <DownloadDocumentMolecule
              id={id}
              title={t("ExitATPVButtonText")}
              typeDocument={TypeDocumentCodeEnum.ExitATPV}
            />
          );
          return [exitTermButton, exitAtpvButton];

        default:
          return undefined;
      }
    },
    [push, setMenuCurrentStepZeroKmContext, setZeroKmVehicleEntryContext, t]
  );

  const getOnClickAccess = useCallback(
    (
      id: string | number,
      status: RenaveStatusEnum,
      isZeroKm: boolean
    ): (() => void) | undefined => {
      let setCurrentStepContext: (v: number) => void;
      let step: number | null;
      let getPath: (v: number) => (id: string | number | null) => string;

      if (isZeroKm) {
        step = RenaveStatusHelper.getZeroKmMenuStepFromStatus(status);
        setCurrentStepContext = setMenuCurrentStepZeroKmContext;
        getPath = RenaveStatusHelper.getZeroKmOnClickAccessPath;
      } else {
        step = RenaveStatusHelper.getMenuStepFromStatus(status);
        setCurrentStepContext = setMenuCurrentStepContext;
        getPath = RenaveStatusHelper.getOnClickAccessPath;
      }

      if (!step) return undefined;

      const path = getPath(step);
      if (!path) return undefined;

      return () => {
        step && setCurrentStepContext(step);
        push(path(id));
      };
    },
    [push, setMenuCurrentStepContext, setMenuCurrentStepZeroKmContext]
  );

  return (
    <>
      <MyStockPageWrapper>
        <MyStockListWrapper>
          {!props.myVehicles || props.isUpdatingVehicles ? (
            <SpinnerCenteredAtom />
          ) : !props.myVehicles.length ? (
            <NoVehiclesFoundDiv>{t("NoVehiclesInStock")}</NoVehiclesFoundDiv>
          ) : (
            props.myVehicles.map((m, i) => (
              <RenaveCardMolecule
                key={`renave-card-${i}`}
                cardData={m}
                getBodyButtons={getButtons}
                getOnClickAccess={getOnClickAccess}
              />
            ))
          )}
        </MyStockListWrapper>

        {props.paginationData && props.paginationData.totalPages > 1 && (
          <PaginationControlMolecule
            paginationData={props.paginationData}
            setPageNumber={props.setPageNumber}
          />
        )}
      </MyStockPageWrapper>

      <RejectEntryModalMolecule
        id={rejectEntryId}
        setId={setRejectEntryId}
        rejectEntryCallback={postRejectZeroKm}
        onRejectSuccessful={() => props.setGoUpdate(true)}
      />

      <CancelRejectionModalMolecule
        id={cancelRejectionId}
        setId={setCancelRejectionId}
        cancelRejectionCallback={postCancelRejectionZeroKm}
        onCancelRejectionSuccessful={() => props.setGoUpdate(true)}
      />
    </>
  );
};
