import { TypeEntryEnum } from './../../enums/entry-type.enum';
import { TypesHelper } from "c4u-web-components";
import { RenaveStatusEnum } from "../..";

export interface IGetVehicleRequest {
  search?: string;
  page: number;
  pageSize: number;
  startDate?: Date | string;
  finishDate?: Date | string;
  step?: RenaveStatusEnum | "";
  updateZeroKm?: boolean;
  isZeroKm: boolean;
  isUsed: boolean;
}

export class GetVehicleRequest {
  search: string | null;
  page: number | null;
  pageSize: number | null;
  startDate: string | null;
  finishDate: string | null;
  step: RenaveStatusEnum | null;
  updateZeroKm: boolean | null;
  isZeroKm: boolean | null;
  isUsed: boolean | null;
  constructor({
    search,
    page,
    pageSize,
    startDate,
    finishDate,
    step,
    updateZeroKm,
    isZeroKm,
    isUsed,
  }: IGetVehicleRequest) {
    this.search = TypesHelper.toStringNull(search);
    this.page = TypesHelper.toNumberNull(page);
    this.pageSize = TypesHelper.toNumberNull(pageSize);
    this.startDate = TypesHelper.toStringNull(startDate);
    this.finishDate = TypesHelper.toStringNull(finishDate);
    this.step = TypesHelper.toNumberNull(step !== "" ? step : null);
    this.updateZeroKm = TypesHelper.toBooleanNull(updateZeroKm);
    this.isZeroKm = TypesHelper.toBooleanNull(isZeroKm);
    this.isUsed = TypesHelper.toBooleanNull(isUsed);
  }
}

export interface IGetVehicleResponse {
  id: number;
  step: RenaveStatusEnum;
  plate?: string;
  stockEntryDate?: string;
  stockExitDate?: string;
  renavam?: string;
  controlStockId?: number;
  vin?: string;
  isZeroKm?: boolean;
  isUsed?: boolean;
  typeEntryEnum?: TypeEntryEnum;
}

export interface IPagedResponseBase {
  currentPage: number;
  totalPages: number;
  recordsPerPage: number;
  totalRecords: number;
}

export interface IGetVehiclePagedResponse extends IPagedResponseBase {
  data?: IGetVehicleResponse[];
}
