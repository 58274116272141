import { FormikControlAtom, TypesHelper } from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { EnumHelper } from "../../../helper";
import { useRenaveContext } from "../../../hooks";
import {
  TypeIndexDocumentEnum,
  TypeMethodSendingAtpveEnum,
  TypeSignatureEnum,
} from "../../../models";
import { IconDefault } from "../../assets/index";
import {
  UploadDocumentImageAtom,
  UploadDocumentPdfAtom,
} from "../../atoms/index";

interface ISendingAtpve {
  typeMethodSendingAtpve: TypeMethodSendingAtpveEnum;
}
interface IFrontAndBackFile {
  icon: any;
  title: string;
  index: number;
}

interface IProps {
  typeMethodSendingAtpve: TypeMethodSendingAtpveEnum;
  isSubmitting?: boolean;
  setValidDocument: (v: boolean) => void;
  typeSignature?: TypeSignatureEnum;
  disableAll?: boolean;
}

export const UploadDocumentMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { documentContext, setDocumentContext } = useRenaveContext();
  const { isSubmitting, setValidDocument, typeSignature } = props;

  const [typeMethodSendingAtpve, setTypeMethodSendingAtpve] =
    useState<TypeMethodSendingAtpveEnum>();

  const formik = useFormik<ISendingAtpve>({
    initialValues: {
      typeMethodSendingAtpve:
        props.typeMethodSendingAtpve ?? TypeMethodSendingAtpveEnum.UniqueFile,
    },
    onSubmit: async (values, { setErrors }) => { },
    validateOnBlur: true,
    validateOnChange: false,
  });

  const sendingAtvpeTypeCombo = useMemo(
    () => EnumHelper.getComboFromEnum(TypeMethodSendingAtpveEnum, t),
    [t]
  );

  const images: IFrontAndBackFile[] = [
    {
      icon: IconDefault,
      title: t("FrontAtpve"),
      index: TypeIndexDocumentEnum.FrontAtpve,
    },
    {
      icon: IconDefault,
      title: t("BackAtpve"),
      index: TypeIndexDocumentEnum.BackAtpve,
    },
  ];

  useEffect(() => {
    const typeMethodSending = formik.values.typeMethodSendingAtpve;
    setTypeMethodSendingAtpve(typeMethodSending);

    if (documentContext) {
      documentContext.typeMethodSending = typeMethodSending;
      setDocumentContext(documentContext);
    }
  }, [formik.values.typeMethodSendingAtpve, setDocumentContext]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!props.disableAll) formik.setFieldValue("typeMethodSendingAtpve", "");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Form>
        <FormikControlAtom
          type="select"
          md={10}
          lg={8}
          data={sendingAtvpeTypeCombo}
          formik={formik}
          property={"typeMethodSendingAtpve"}
          label={t("SelectAtpveSendingMethod")}
          translate={t}
          disabled={!!props.disableAll}
        />
      </Form>

      <Col md={10} lg={9} xl={8}>
        {TypesHelper.toNumberNull(typeMethodSendingAtpve) ===
          TypesHelper.toNumberNull(
            TypeMethodSendingAtpveEnum.FrontAndBackFiles
          ) && (
            <Row>
              {images.map((m, i) => (
                <Col
                  key={`upload-image-${i}`}
                  xs={{ span: 10, offset: 1 }}
                  sm={{ span: 6, offset: 0 }}
                  className="mb-3 mb-sm-0"
                >
                  <UploadDocumentImageAtom
                    icon={m.icon}
                    title={m.title}
                    index={m.index}
                    isSubmitting={isSubmitting}
                    setValidDocument={setValidDocument}
                  />
                </Col>
              ))}
            </Row>
          )}

        {TypesHelper.toNumberNull(typeMethodSendingAtpve) ===
          TypesHelper.toNumberNull(TypeMethodSendingAtpveEnum.UniqueFile) && (
            <Row>
              <Col
                xs={{ span: 10, offset: 1 }}
                sm={{ span: 8, offset: 0 }}
                className="mb-3 mb-sm-0"
              >
                <UploadDocumentPdfAtom
                  title={t("PdfDocument")}
                  index={TypeIndexDocumentEnum.PdfDocument}
                  isSubmitting={isSubmitting}
                  setValidDocument={setValidDocument}
                  typeSignature={typeSignature}
                  disabled={!!props.disableAll}
                />
              </Col>
            </Row>
          )}
      </Col>
    </>
  );
};
