import styled from "styled-components";

export const WrapperSearch = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 10px 0 10px 0;
 
 
  .form-control {
    height: 41px;
  }

  .btn-link {
    height: 40px;
  }
`;

export const CleanFiltersDiv = styled.div`
  text-align: center;
  white-space: nowrap;
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 22px;

  text-transform: uppercase;
  color: ${(props) => props.theme.colors.BlueNavy};

  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  opacity: ${(props) => (props.onClick ? 1 : 0.4)};
  margin: 15px;

  :hover {
    text-decoration-line: underline;
  }
`;

export const MenuIconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 30px 0 0 10px;
  width: 266px;

  @media (max-width: 991px) {
    width: 250px;
  }

  @media (max-width: 767px) {
    width: 230px;
  }

  .btn-link {
    margin: -2px 34px 0px 7px;
  }
`;

export const WrapperFilterIsOpen = styled.div`
  width: 130%;
  display: flex;
  align-items: center;
`;

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 10px 0 0 0;
`;
