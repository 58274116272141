import { useAuth0 } from "@auth0/auth0-react";
import {
  ActiveItemTypeConstant,
  AuthHelper,
  C4UMenu,
  CorporateUserTypeEnum,
  IAuth0User,
  ICoxUser,
  IDealershipComboData,
  IUser,
  StorageConstant,
} from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useC4u, useRenaveContext, useSessionContext } from "../../../hooks";
import { FeedbackModal } from "../../molecules";
import { AuthTemplate } from "../auth/auth.template";
import { AppContainerWithMenu } from "./main.template.style";

export const MainTemplate: React.FC = (props) => {
  const { t } = useTranslation();

  const { isAuthenticated, isLoading } = useAuth0();
  const {
    GetAuth0User,
    GetAdminUser,
    GetDealership,
    postSelectDealership,
    getListMyDealerships,
  } = useC4u();
  const { setUserContext } = useRenaveContext();

  const {
    genericModalMsg,
    genericErrorModal,
    hideGenericErrorModal,
    genericWarningModal,
    hideGenericWarningModal,
    clickButtonGenericWarningModal,
    genericSuccessModal,
    hideGenericSuccessModal,
    titleErrorModal,
    textHeaderErrorModal,
    titleWarningModal,
    titleSuccessModal,
    textHeaderSucessModal,
  } = useSessionContext();

  //#region auth

  const [auth0User, setAuth0User] = useState<IAuth0User>();
  const [coxUser, setCoxUser] = useState<ICoxUser>();
  const [redirectedFromLogin, setRedirectedFromLogin] = useState(false);
  const [selectedDealership, setSelectedDealership] =
    useState<IDealershipComboData>();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      GetAuth0User().then((t) => setAuth0User(t));
      GetAdminUser().then((t) => {
        setCoxUser(t);
        GetDealership(t.dealershipId ?? 0).then((data) => {
          setUserContext({ dealership: data, user: t });
        });
      });
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticated]);

  const [userCox, setUserCox] = useState<IUser>();
  useEffect(() => {
    if (coxUser && auth0User) {
      setUserCox(AuthHelper.GetCoxUser(coxUser, auth0User));
      const { dealershipId, dealershipName, dealershipCnpj } = coxUser;
      if (dealershipId && dealershipName && dealershipCnpj)
        setSelectedDealership({
          id: dealershipId,
          name: dealershipName,
          cnpj: dealershipCnpj,
        });
      setRedirectedFromLogin(
        sessionStorage.getItem(StorageConstant.redirectedFromLogin) === "true"
      );
    }
  }, [auth0User, coxUser]);
  //#endregion

  const handleLogout = () => {
    window.location.href = process.env.REACT_APP_LOGOUT_URL ?? "";
  };

  const clearKeysFromStorage = useCallback(() => {
    sessionStorage.removeItem(StorageConstant.redirectedFromLogin);
  }, []);

  return (
    <>
      <AuthTemplate>
        <C4UMenu
          handleLogout={handleLogout}
          activeMenu={ActiveItemTypeConstant.RENAVE}
          environment={process.env.REACT_APP_BUILD}
          user={userCox}
          selectDealershipProps={
            !userCox
              ? undefined
              : {
                  userId: coxUser?.id,
                  userIsManager:
                    coxUser?.corporateUserType ===
                    CorporateUserTypeEnum.Manager,
                  userIsCorporate: coxUser?.isCorporate,
                  dealership: selectedDealership,
                  onSubmitCallback: postSelectDealership,
                  getDealershipsPagedListCallback: getListMyDealerships,
                  setSelectedDealership: setSelectedDealership,
                  onHideCallback: clearKeysFromStorage,
                  forceShowModal: redirectedFromLogin,
                }
          }
          translate={t}
          dealershipUser={coxUser?.dealershipCnpj}
        />
        <AppContainerWithMenu>
          <Container>{props.children}</Container>
        </AppContainerWithMenu>
      </AuthTemplate>
      <FeedbackModal
        size="md"
        typeIcon="error"
        show={genericErrorModal}
        onHide={hideGenericErrorModal}
        titleHeader={textHeaderErrorModal ?? ""}
        title={titleErrorModal ?? t("A problem found")}
        message={genericModalMsg ?? t("GenericErrorMsg")}
      />
      <FeedbackModal
        size="md"
        typeIcon="warning"
        show={genericWarningModal}
        onHide={hideGenericWarningModal}
        onClickButton={clickButtonGenericWarningModal}
        title={titleWarningModal ?? t("Attention")}
        message={genericModalMsg ?? t("GenericWarningMsg")}
      />
      <FeedbackModal
        size="md"
        typeIcon="success"
        show={genericSuccessModal}
        onHide={hideGenericSuccessModal}
        titleHeader={textHeaderSucessModal ?? ""}
        title={titleSuccessModal ?? t("Everything all right")}
        message={genericModalMsg ?? t("GenericSuccessMsg")}
      />
    </>
  );
};
