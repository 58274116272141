import { Form } from "react-bootstrap";
import styled from "styled-components";

export const MyStockHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;

  margin: 20px 0;
`;

export const MenuIconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  width: 320px;

  @media (max-width: 991px) {
    width: 250px;
  }

  @media (max-width: 767px) {
    width: 230px;
  }

  .btn-primary {
    :focus {
      background-color: ${({ theme }) => theme.colors.BlueNavy};
      path {
       fill: #ffff
      }
    }
    :not(:disabled):not(.disabled):active {
      background-color: ${({ theme }) => theme.colors.BlueNavy};
      path {
       fill: #ffff
      }
    }
  }
`;

export const CleanFiltersDiv = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  text-transform: uppercase;
  color: ${(props) => props.theme.colors.BlueNavy};

  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  opacity: ${(props) => (props.onClick ? 1 : 0.4)};

  :hover {
    text-decoration-line: underline;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  width: 13rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FormCheckboxWrapper = styled.div<{ error?: boolean }>`
  width: 13rem;
  flex-direction: row;
  gap: 15px;
  padding: ${(props) => (props.error ? "0px 0px" : "10px 0px")};
  justify-content: center;
  align-self: center;
  margin-bottom: ${(props) => (props.error ? "0px" : "10px")};
  display: flex !important;
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  font-size: 10px;
`;

export const ClickableDiv = styled.div`
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
`;

export const WrapperReload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  /* transition: all 0.3s ease-out; */
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 100px;
  padding: 8px;

  :hover {
    background-color: #F2F2F3;
    border-color: #E1E1E2;
  }
`;

export const RotatableClickableDiv = styled(ClickableDiv) <{
  isLoading?: boolean;
}>`
  animation: rotate linear 1s ${(props) => (props.isLoading ? "infinite" : "0")};

  @keyframes rotate {
    0% {
      transform: rotate(0deg) translate(0, -0.78px);
    }
    100% {
      transform: rotate(360deg) translate(0, -0.78px);
    }
  }
`;

export const FiltersRow = styled(Form.Row)`
  padding: 16px 8px;
  border-radius: 4px;
  background-color: #F2F2F3;
  div.form-group label {
    font-weight: bold;
  }
  div.col-6 label {
    font-weight: bold;
  }
`
