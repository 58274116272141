import styled from "styled-components";

export const WrapperUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;
  gap: 5px;

  span {
    color: #a8aaac;
    font-family: ${(props) => props.theme.fontFamily.OpenSans};
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 22px;
  }
`;
