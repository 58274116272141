import styled from "styled-components";

export const ContentCardWrapper = styled.div`
  display: flex;
  flex-direction: column;

  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  .form-row {
    display: flex;
    align-items: baseline;
  }

  hr {
    border: none;
    height: 40px;
    width: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    margin: -50px auto 10px;
  }
`;

export const CardTitle = styled.div`
  background: ${(props) => props.theme.colors.COXWhite};
  padding: 15px 25px;
  border-radius: 10px 10px 0px 0px;

  font-family: ${(props) => props.theme.fontFamily.Roboto};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;

  color: ${(props) => props.theme.colors.Dark};
`;

export const CardContent = styled.div`
  padding: 35px 25px;
  background: ${(props) => props.theme.colors.COXWhite};
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 10px 10px;

  z-index: 1;
`;
