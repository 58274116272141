import React from "react";
import { HashRouter, Switch } from "react-router-dom";
import { Route } from "react-router-dom";

import { routes } from "../constants";
import { MainTemplate } from "../ui/templates";

const Routes: React.FC<any> = () => {
  const mapRoutes = (): Array<JSX.Element> => {
    return routes.map((route: any, key) => (
      <Route
        key={key}
        path={route.path}
        exact={true}
        render={(props) => <route.component {...props} />}
      />
    ));
  };

  return (
    <MainTemplate>
      <HashRouter>
        <Switch>{mapRoutes()}</Switch>
      </HashRouter>
    </MainTemplate>
  );
};

export { Routes };
