import {
  GetCreditStatementRequest,
  GetDocumentRequest,
  GetPurchaseOrderRequest,
  GetRestrictionRequest,
  GetSecurityCodeCrvResquest,
  GetStatusRequest,
  GetVehicleRequest,
  IGetAvailableState,
  IGetCheckDealershipResponse,
  IGetCreditStatementResponse,
  IGetDailyBalance,
  IGetDocumentResponse,
  IGetPacksResponse,
  IGetRestrictionResponse,
  IGetSecurityCodeCrvResponse,
  IGetStatusResponse,
  IGetSummaryResponse,
  IGetVehicleByIdResponse,
  IGetVehiclePagedResponse,
  IPostDealershipResponse,
  IPostPackResponse,
  IPostVehicleResponse,
  IPurchaseOrderPagedResponse,
  PostCancelRejectionZeroKmRequest,
  PostCheckInZeroKmRequest,
  PostCustomerCheckOutZeroKmRequest,
  PostDealershipCheckOutZeroKmRequest,
  PostDealershipRequest,
  PostEntryCancellationRequest,
  PostExitCancellationRequest,
  PostPacksRequest,
  PostRejectZeroKmRequest,
  PostReturnZeroKmRequest,
  PostVehicleRequest,
  SaveOutOfStockDealershipRequest,
  SaveOutOfStockPersonRequest,
  UploadDocumentRequest,
  VehicleEntryRequest,
} from "../../../models";
import { NotifySignatureRequest } from "../../../models/apis/renave/notify-signature.model";
import {
  IVehicleDanfeResponse,
  VehicleDanfeRequest,
} from "../../../models/apis/renave/vehicle-danfe.request";
import { renaveHttp, useRequest } from "../config";

export const useRenave = () => {
  const { post, get } = useRequest(renaveHttp, "renave");

  const saveOutOfStock = async (
    request: SaveOutOfStockDealershipRequest | SaveOutOfStockPersonRequest
  ) => {
    await post(`checkOutApplication`, request);
  };

  const getNumberSecurityCodeCrv = async (
    request: GetSecurityCodeCrvResquest
  ) => {
    const { data } = await post<IGetSecurityCodeCrvResponse>(
      `securityCodeCrv`,
      request
    );
    return data;
  };

  const getRestriction = async (request: GetRestrictionRequest) => {
    const { data } = await post<IGetRestrictionResponse>(
      `getRestriction`,
      request
    );
    return data;
  };

  const getRenaveStock = async (request: GetVehicleRequest) => {
    const { data } = await post<IGetVehiclePagedResponse>(
      `getVehicle`,
      request
    );
    return data;
  };

  const getRenaveVehicleById = async (id: string | number) => {
    const { data } = await get<IGetVehicleByIdResponse>(`vehicle/${id}`);
    return data;
  };

  const getDailyBalance = async () => {
    const { data } = await get<IGetDailyBalance>(`dailyBalance`);
    return data;
  };

  const getCheckDealership = async () => {
    const { data } = await get<IGetCheckDealershipResponse>(
      `checkDealershipRegistered`
    );
    return data;
  };

  const uploadDocument = async (
    uploadRequest: UploadDocumentRequest,
    onUploadProgress?: any
  ) => {
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress,
    };

    const formData = new FormData();
    formData.append("Plate", uploadRequest.plate ?? "");
    formData.append("Renavam", uploadRequest.renavam ?? "");
    formData.append("NumberCrv", uploadRequest.numberCrv ?? "");
    formData.append("TypeCrv", uploadRequest.typeCrv?.toString() ?? "");
    formData.append(
      "TypeSignature",
      uploadRequest.typeSignature?.toString() ?? ""
    );
    formData.append("File", uploadRequest.file ?? new Blob(), "File");

    await post(`uploadDocument/`, formData, config);
  };

  const postNotifySignature = async (request: NotifySignatureRequest) => {
    await post("notifySignature", request);
  };

  const getDocument = async (getDocumentRequest: GetDocumentRequest) => {
    const { data } = await post<IGetDocumentResponse>(
      `downloadDocument`,
      getDocumentRequest
    );
    return data;
  };

  const getSummary = async () => {
    const { data } = await get<IGetSummaryResponse>(`summary`);
    return data;
  };

  const postEntryCancellation = async (
    request: PostEntryCancellationRequest
  ) => {
    await post("entryCancellation", request);
  };

  const postEntryVehicle = async (request: VehicleEntryRequest) => {
    await post(`entryVehicle`, request);
  };

  const postVehicle = async (request: PostVehicleRequest) => {
    const { data } = await post<IPostVehicleResponse>(`vehicle`, request);
    return data;
  };

  const postVehicleDanfe = async (request: VehicleDanfeRequest) => {
    const { data } = await post<IVehicleDanfeResponse>(`sendInvoce`, request);
    return data;
  };

  const postExitCancellation = async (request: PostExitCancellationRequest) => {
    await post("checkOutCancellation", request);
  };

  const getCreditStatement = async (request: GetCreditStatementRequest) => {
    const { data } = await post<IGetCreditStatementResponse[]>(
      `getCreditStatement`,
      request
    );
    return data;
  };

  const postDealership = async (request: PostDealershipRequest) => {
    const { data } = await post<IPostDealershipResponse>("dealership", request);
    return data;
  };

  const getStatus = async (request: GetStatusRequest) => {
    const { data } = await post<IGetStatusResponse>("getStatus", request);
    return data;
  };

  const getStockReport = async () => {
    const response = await get<BlobPart>(`stockReport`);
    return response;
  };

  const getPacks = async () => {
    const { data } = await get<IGetPacksResponse>(`packs`);
    return data;
  };

  const postPacks = async (request: PostPacksRequest) => {
    const { data } = await post<IPostPackResponse>("packs", request);
    return data;
  };

  const getAvailableState = async () => {
    const { data } = await get<IGetAvailableState>(`availableState`);
    return data;
  };

  const postRejectZeroKm = async (request: PostRejectZeroKmRequest) => {
    await post("rejectZeroKm", request);
  };

  const postCancelRejectionZeroKm = async (
    request: PostCancelRejectionZeroKmRequest
  ) => {
    await post("cancelRejectionZeroKm", request);
  };

  const postCheckInZeroKm = async (request: PostCheckInZeroKmRequest) => {
    await post("checkInZeroKm", request);
  };

  const postReturnZeroKm = async (request: PostReturnZeroKmRequest) => {
    await post("returnZeroKm", request);
  };

  const postCheckOutZeroKm = async (
    request:
      | PostDealershipCheckOutZeroKmRequest
      | PostCustomerCheckOutZeroKmRequest
  ) => {
    await post(`checkOutZeroKm`, request);
  };

  const purchaseOrdersList = async (request: GetPurchaseOrderRequest) => {
    const { data } = await post<IPurchaseOrderPagedResponse>(
      `purchaseOrdersList`,
      request
    );
    return data;
  };

  const getUpdateVehicles = async () => {
    try {
      await get(`updateVehicles`);
      return true;
    } catch {
      return false;
    }
  };

  return {
    getRestriction,
    getDailyBalance,
    getRenaveStock,
    getRenaveVehicleById,
    getPacks,
    getSummary,
    uploadDocument,
    getCheckDealership,
    getDocument,
    postEntryVehicle,
    postEntryCancellation,
    postVehicle,
    postDealership,
    postPacks,
    saveOutOfStock,
    postVehicleDanfe,
    postExitCancellation,
    getCreditStatement,
    getStatus,
    getStockReport,
    getAvailableState,
    postRejectZeroKm,
    postCancelRejectionZeroKm,
    postCheckInZeroKm,
    postReturnZeroKm,
    postCheckOutZeroKm,
    purchaseOrdersList,
    getUpdateVehicles,
    postNotifySignature,
    getNumberSecurityCodeCrv,
  };
};
