import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const ModalBody = styled.div<{ dataCy?: string }>`
  background-color: ${(props) => props.theme.colors.Background};
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 60px 60px;
  border-radius: 5px;
  gap: 15px;

  &.reduce-padding-top {
    padding-top: 24px;
  }
`;

export const Icon = styled.div`
  text-align: center;
`;

export const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.DarkBlue};
  text-align: center;
  line-height: 28px;
  margin: 0;
`;

export const WrapperButtonsConfirm = styled.div`
  display: flex;
  margin: 5px 0;
  button:first-child {
    margin-right: 20px;
  }
`;

export const Content = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ModalHeader = styled(Modal.Header)`
  background: ${(props) => props.theme.colors.Background};
  height: 60px;
  justify-content: flex-end;
  align-items: center;

  font-family: ${(p) => p.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.15px;
  color: #1f3e74;

  span {
    font-size: 30px;
    font-weight: normal;
  }
  button {
    padding: 0;
    margin: 0;
    opacity: 1;
  }
`;

export const ModalFooter = styled(Modal.Footer)`
  background: ${(props) => props.theme.colors.Background};
  justify-content: center;
  align-items: center;

  border-top: 0;
  padding: 0 0 36px;
  gap: 32px;
`;

export const ContentModalMsg = styled.div`
  color: ${(props) => props.theme.colors.Dark};
  text-align: center;
  max-width: 500px;
`;
