import React, { useEffect } from "react";
import { MaganeStockZeroKmBasePage } from "../..";
import { useRenaveContext } from "../../../../hooks";
import { RenaveMenuStepsZeroKmEnum } from "../../../../models";
import { ZeroKmVehicleCheckedOutOrganism } from "../../../organisms/zero-km-vehicle-checked-out/zero-km-vehicle-checked-out.organism";

export const VehicleCheckedOutZeroKmPage: React.FC = () => {
  const {
    menuCurrentStepZeroKmContext,
    menuSelectedStepZeroKmContext,
    setMenuSelectedStepZeroKmContext,
  } = useRenaveContext();

  useEffect(() => {
    setMenuSelectedStepZeroKmContext(
      RenaveMenuStepsZeroKmEnum.CheckedOutZeroKm
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MaganeStockZeroKmBasePage>
      <ZeroKmVehicleCheckedOutOrganism
        viewMode={menuSelectedStepZeroKmContext < menuCurrentStepZeroKmContext}
      />
    </MaganeStockZeroKmBasePage>
  );
};
