import { TypesHelper } from "c4u-web-components";
import { CheckoutTypeEnum, TypeDocumentEnum } from "../..";

export interface ISaveOutOfStockDealershipRequest {
  id: string;
  businessNumber: string;
  email: string;
  saleValue: number;
  exitDate: Date;
}

export class SaveOutOfStockDealershipRequest {
  id: number | null;
  buyerDocument: string | null;
  buyerEmail: string | null;
  saleValue: number | null;
  buyerDocumentType: number | null;
  isEdit: boolean | null;
  checkOutType: CheckoutTypeEnum | null;
  stockExitDate: Date | null;

  constructor({
    id,
    businessNumber,
    email,
    saleValue,
    exitDate,
  }: ISaveOutOfStockDealershipRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.buyerDocumentType = TypeDocumentEnum.Cnpj;
    this.buyerDocument = TypesHelper.toStringOnlyNumbersNull(businessNumber);
    this.buyerEmail = TypesHelper.toStringNull(email);
    this.saleValue = TypesHelper.toNumberNull(saleValue);
    this.isEdit = false;
    this.checkOutType = TypesHelper.toNumberNull(
      CheckoutTypeEnum.TransferAuthorization
    );
    this.stockExitDate = TypesHelper.toDateNull(exitDate);
  }
}
