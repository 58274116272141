import {
  Combo,
  IKbbVehicleModel,
  SortHelper,
  SourcePartnerEnum,
  VehicleCategoriesEnum,
  VehicleSearchOptions,
  VehicleSearchOptionsProps,
} from "c4u-web-components"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useKbb, useRenaveContext } from "../../../hooks"
import { Years } from "../../../models"

export interface IVehicleKbbProps {
  id: number;
  idBrand: number;
  year: number;
  idModel: number;
  molicarID: string;
}

class CustomCombo extends Combo {
  selected?: boolean;
  constructor({ title, value, selected = false }: any) {
    super({ title, value });
    this.selected = selected;
  }
}

interface IProps {
  disableAll?: boolean;
  vehicleKbb?: IVehicleKbbProps;
  isZeroKm?: boolean;
  setHasSelectedCategory: (value: boolean) => void;
}

export const VehicleOptions: React.FC<IProps> = (parameters) => {
  const { t } = useTranslation();

  const [props, setProps] = useState(parameters);
  useEffect(() => {
    setProps(parameters);
  }, [parameters]);

  const {
    setKbbVehicleContext,
    vehicleCategoryContext,
    sourcePartnerContext,
    setSourcePartnerContext,
  } = useRenaveContext();

  const {
    GetAllVehicleBrand,
    GetAllVehicleModelByBrand,
    GetAllVehicleVersion,
    GetAllYears,
    GetVehicle,
  } = useKbb();

  const [editMode, setEditMode] = useState<boolean>();

  const [vehicleBrandCombo, setVehicleBrandCombo] = useState<CustomCombo[]>();
  const [vehicleModelCombo, setVehicleModelCombo] = useState<CustomCombo[]>();
  const [vehicleVersionCombo, setVehicleVersionCombo] = useState<Combo[]>();
  const [yearsCombo, setYearsCombo] = useState<Combo[]>();

  const [brandIsLoading, setBrandIsLoading] = useState(false);
  const [modelIsLoading, setModelIsLoading] = useState(false);
  const [versionIsLoading, setVersionIsLoading] = useState(false);

  const setYearsComboCallback = useMemo(() => {
    if (props.isZeroKm) {
      const currentYear = new Date().getFullYear();
      //! currentYear - 2 is a mock
      const defaultYears = [
        currentYear + 1,
        currentYear,
        currentYear - 1,
        currentYear - 2,
      ];
      return (_: Combo[]) =>
        setYearsCombo(
          defaultYears.map(
            (year) =>
              new Combo({
                title: year === 2023 ? `${year}*` : year,
                value: year,
              })
          )
        );
    } else {
      return setYearsCombo;
    }
  }, [props.isZeroKm]);

  const vehicleBrandSelected = vehicleBrandCombo?.find((item) => item.selected);
  const vehicleModelSelected = vehicleModelCombo?.find((item) => item.selected);

  const [vehicleYearSelected, setVehicleYearSelected] = useState<Combo>();
  const [vehicleVersionSelected, setVehicleVersionSelected] = useState<Combo>();

  const [vehicleYears, setVehicleYears] = useState<Years[]>();
  const [vehicleModels, setVehicleModels] = useState<IKbbVehicleModel[]>();
  const [isLoading, setIsLoading] = useState(false);

  const isCarCategory = useMemo(
    () => vehicleCategoryContext === VehicleCategoriesEnum.Car,
    [vehicleCategoryContext]
  );

  const isSourceKbb = useMemo(
    () => sourcePartnerContext === SourcePartnerEnum.Kbb,
    [sourcePartnerContext]
  );

  const getVehiclesBrandAsync = useCallback(async (): Promise<void> => {
    setBrandIsLoading(true);
    setVehicleBrandCombo([]);
    setVehicleBrandCombo(undefined);
    const items = await GetAllVehicleBrand(
      vehicleCategoryContext,
      sourcePartnerContext
    );
    const itemsCombo = items?.map(
      (m) =>
        new CustomCombo({
          title: m.name,
          value: m.id,
          selected: false,
        })
    );
    setVehicleBrandCombo(itemsCombo);
    setBrandIsLoading(false);
  }, [GetAllVehicleBrand, sourcePartnerContext, vehicleCategoryContext]);

  const getVehicleVersionAsync = useCallback(
    async (modelId: number, year: number): Promise<void> => {
      setVehicleVersionCombo([]);
      if (modelId && year) {
        setVersionIsLoading(true);
        const items = await GetAllVehicleVersion(
          modelId,
          year,
          sourcePartnerContext
        );
        const itemsCombo = items?.map(
          (m) =>
            new Combo({
              title: m.name,
              value: isSourceKbb ? m.kbbid : m.molicarID,
            })
        );
        setVehicleVersionCombo(itemsCombo);
        setVersionIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSourceKbb, vehicleCategoryContext]
  );

  const getVehiclesModelAsync = useCallback(
    async (brandId: number, year: number | null = null): Promise<void> => {
      if (brandId) {
        setModelIsLoading(true);
        const items = await GetAllVehicleModelByBrand(
          brandId,
          year,
          vehicleCategoryContext,
          sourcePartnerContext
        );
        const itemsCombo = items?.map(
          (m) =>
            new CustomCombo({
              title: m.name,
              value: m.id,
              selected: false,
            })
        );
        setVehicleModelCombo(itemsCombo);
        setVehicleModels(items);
        setModelIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [GetAllVehicleModelByBrand, getVehicleVersionAsync, vehicleCategoryContext]
  );

  const getVehicleAsync = useCallback(
    async (
      id: number,
      molicarID: string | null,
      year: number
    ): Promise<void> => {
      setIsLoading(true);
      try {
        const vehicle = await GetVehicle(id, molicarID, year);
        setKbbVehicleContext(vehicle);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [GetVehicle, setKbbVehicleContext]
  );

  const GetAllYearsAsync = useCallback(async (): Promise<void> => {
    const items = await GetAllYears(sourcePartnerContext);
    setVehicleYears(items);
    let itemsCombo = items?.map(
      (m) =>
        new Combo({
          title: m.year,
          value: m.year,
        })
    );
    setYearsComboCallback(itemsCombo);
  }, [GetAllYears, sourcePartnerContext, setYearsComboCallback]);

  const resetForm = () => {
    setVehicleBrandCombo((state) =>
      state?.map((item) => {
        return { ...item, selected: false };
      })
    );
    props.setHasSelectedCategory(false);
    setVehicleModelCombo(undefined);
    setVehicleModels(undefined);
    setKbbVehicleContext(undefined);
    setVehicleVersionCombo(undefined);
    setVehicleVersionSelected(undefined);
    setVehicleYearSelected(undefined);
  };

  const handleChangeBrand = useCallback(
    (comboValue: Combo) => {
      if (!comboValue) {
        resetForm();
        return;
      }
      setVehicleBrandCombo((state) =>
        state?.map((item) => {
          if (item?.value === comboValue?.value) {
            return { ...item, selected: true };
          }
          return { ...item, selected: false };
        })
      );
      setVehicleModelCombo((state) =>
        state?.map((item) => {
          return { ...item, selected: false };
        })
      );
      setVehicleVersionSelected(undefined);
      setKbbVehicleContext(undefined);
      props.setHasSelectedCategory(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setKbbVehicleContext]
  );

  const handleChangeYear = useCallback(
    (comboValue: Combo) => {
      setVehicleYearSelected(comboValue);
      setVehicleVersionSelected(undefined);
      setVehicleVersionCombo([]);
      setKbbVehicleContext(undefined);
      setVehicleModelCombo([]);
    },
    [setKbbVehicleContext]
  );

  const handleChangeModel = useCallback(
    (comboValue: Combo) => {
      setVehicleModelCombo((state) =>
        state?.map((item) => {
          if (item?.value === comboValue?.value) {
            return { ...item, selected: true };
          }
          return { ...item, selected: false };
        })
      );
      setVehicleVersionSelected(undefined);
      setKbbVehicleContext(undefined);
    },
    [setKbbVehicleContext]
  );

  const handleChangeVersion = useCallback(
    (comboValue: Combo) => {
      setVehicleVersionSelected(comboValue);
      setKbbVehicleContext(undefined);
    },
    [setKbbVehicleContext]
  );

  useEffect(() => {
    if (
      vehicleModelSelected &&
      vehicleModelCombo &&
      vehicleYearSelected &&
      vehicleYearSelected?.value
    ) {
      const year = Number(vehicleYearSelected.value);
      const modelExists = vehicleModelCombo.find(
        (f) => f.value === vehicleModelSelected?.value
      );
      if (modelExists) {
        getVehicleVersionAsync(Number(modelExists.value), year);
      } else {
        setVehicleModelCombo((state) =>
          state?.map((item) => {
            return { ...item, selected: false };
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getVehicleVersionAsync, vehicleModelCombo, vehicleYearSelected]);

  useEffect(() => {
    const callBrands = async () => {
      await getVehiclesBrandAsync();
      GetAllYearsAsync();
    };
    callBrands();
  }, [sourcePartnerContext]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editMode === undefined && props.vehicleKbb) setEditMode(true);
  }, [props.vehicleKbb]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //! this useEffect runs again when kbbVehicleContext is updated. To fix this we need to transform vehicleBrandSelected into a react state (probably)
    if (vehicleBrandSelected && vehicleYearSelected && !vehicleVersionSelected)
      getVehiclesModelAsync(
        Number(vehicleBrandSelected.value),
        Number(vehicleYearSelected.value)
      );
  }, [vehicleYearSelected, vehicleBrandSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleModelSelected && vehicleYearSelected)
      getVehicleVersionAsync(
        Number(vehicleModelSelected.value),
        Number(vehicleYearSelected.value)
      );
  }, [vehicleYearSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleModels && vehicleModelSelected) {
      const modelSelected = vehicleModels.find(
        (f) => f.id === vehicleModelSelected.value
      );

      const itemsComboYears = modelSelected?.years.sort(SortHelper.desc).map(
        (y) =>
          new Combo({
            title: y,
            value: y,
          })
      );
      if (itemsComboYears) setYearsComboCallback(itemsComboYears);
    } else {
      const itemsCombo = vehicleYears?.map(
        (m) =>
          new Combo({
            title: m.year,
            value: m.year,
          })
      );

      if (itemsCombo) setYearsComboCallback(itemsCombo);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (vehicleVersionSelected) {
      const vehicleYearValue = Number(vehicleYearSelected?.value);

      if (isSourceKbb) {
        getVehicleAsync(
          Number(vehicleVersionSelected.value),
          null,
          vehicleYearValue
        );
      } else {
        getVehicleAsync(
          0,
          vehicleVersionSelected.value.toString(),
          vehicleYearValue
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleVersionSelected, vehicleYearSelected?.value]);

  //EDITMODE
  useEffect(() => {
    if (editMode && vehicleBrandCombo && yearsCombo) {
      const brand = vehicleBrandCombo?.find(
        (f) => f.value === props.vehicleKbb?.idBrand
      );

      setVehicleBrandCombo((state) =>
        state?.map((item) => {
          if (item?.value === brand?.value) {
            return { ...item, selected: true };
          }
          return { ...item, selected: false };
        })
      );

      const year = yearsCombo?.find((f) => f.value === props.vehicleKbb?.year);
      setVehicleYearSelected(year);
    }
  }, [editMode, yearsCombo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editMode && vehicleModelCombo) {
      const model = vehicleModelCombo?.find(
        (f) => f.value === props.vehicleKbb?.idModel
      );
      setVehicleModelCombo((state) =>
        state?.map((item) => {
          if (item?.value === model?.value) {
            return { ...item, selected: true };
          }
          return { ...item, selected: false };
        })
      );
    }
  }, [editMode, props.vehicleKbb, vehicleModels]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editMode && vehicleVersionCombo) {
      let version: Combo | undefined;
      if (isSourceKbb) {
        version = vehicleVersionCombo?.find(
          (f) => f.value === props.vehicleKbb?.id
        );
      } else {
        version = vehicleVersionCombo?.find(
          (f) => f.value === props.vehicleKbb?.molicarID
        );
      }

      setVehicleVersionSelected(version);
      setEditMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, vehicleVersionCombo]);

  useEffect(() => {
    setVehicleVersionSelected(undefined);
    setVehicleBrandCombo((state) =>
      state?.map((item) => {
        return { ...item, selected: false };
      })
    );
    setVehicleModelCombo((state) =>
      state?.map((item) => {
        return { ...item, selected: false };
      })
    );
    setVehicleYearSelected(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCarCategory) {
      setSourcePartnerContext(SourcePartnerEnum.Kbb);
      return;
    }
    setSourcePartnerContext(SourcePartnerEnum.Molicar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCarCategory, sourcePartnerContext]);

  const propsVehicleSearchOptions = useMemo((): VehicleSearchOptionsProps => {
    return {
      Dropdowns: {
        haveYearsSelected: !!vehicleYearSelected,
        Brand: {
          placeholder: t("Select") + "...",
          Options: vehicleBrandCombo,
          onChange: handleChangeBrand,
          Value: vehicleBrandSelected as Combo,
          disabled: isLoading || props.disableAll,
          needLoading: brandIsLoading,
        },
        Model: {
          placeholder: t("Select") + "...",
          Options: vehicleModelCombo,
          onChange: handleChangeModel,
          Value: vehicleModelSelected as Combo,
          disabled: isLoading || props.disableAll,
          needLoading: modelIsLoading,
        },
        Version: {
          placeholder: t("Select") + "...",
          Options: vehicleVersionCombo,
          onChange: handleChangeVersion,
          Value: vehicleVersionSelected,
          disabled: isLoading || props.disableAll,
          needLoading: versionIsLoading,
        },
        Year: {
          placeholder: t("Select") + "...",
          Options: yearsCombo,
          onChange: handleChangeYear,
          Value: vehicleYearSelected,
          disabled: isLoading || props.disableAll,
        },
      },
      Texts: {
        Brand: t("Brand"),
        ClearFilter: t("Clean Filters"),
        Loading: t("Loading"),
        Model: t("Model"),
        NoMatchesFound: t("NoMatchesFound"),
        Others: t("Others"),
        TitleYears: t("ModelYear"),
        Version: t("Version"),
      },
    };
  }, [
    vehicleYearSelected,
    t,
    vehicleBrandCombo,
    handleChangeBrand,
    vehicleBrandSelected,
    isLoading,
    props.disableAll,
    brandIsLoading,
    vehicleModelCombo,
    handleChangeModel,
    vehicleModelSelected,
    modelIsLoading,
    vehicleVersionCombo,
    handleChangeVersion,
    vehicleVersionSelected,
    versionIsLoading,
    yearsCombo,
    handleChangeYear,
  ]);

  return (
    <Row>
      <Col>
        <VehicleSearchOptions {...propsVehicleSearchOptions} />
      </Col>
    </Row>
  );
};
