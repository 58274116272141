
import { TypesHelper } from "c4u-web-components";
import { TypeSignatureEnum, TypeCrvEnum } from "../../index";

export interface INotifySignatureRequest {
  plate: string;
  renavam: string;
  numberCrv: string;
  typeCrv?: TypeCrvEnum;
  typeSignature?: TypeSignatureEnum;
}

export class NotifySignatureRequest {
  plate: string | null;
  renavam: string | null;
  numberCrv: string | null;
  typeCrv?: TypeCrvEnum | null;
  typeSignature?: TypeSignatureEnum | null;

  constructor({
    plate,
    renavam,
    numberCrv,
    typeCrv,
    typeSignature,
  }: INotifySignatureRequest) {
    this.plate = TypesHelper.toStringNull(plate.replace("-", ""));
    this.renavam = TypesHelper.toStringOnlyNumbersNull(renavam);
    this.numberCrv = TypesHelper.toStringOnlyNumbersNull(numberCrv);
    this.typeCrv = TypesHelper.toNumberNull(typeCrv);
    this.typeSignature = TypesHelper.toNumberNull(typeSignature);
  }
}
