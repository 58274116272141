import { ButtonTertiary } from "c4u-web-components";
import styled from "styled-components";

interface IProps {
  isScreenCreditStatementOrOrderList?: boolean;
}

const HeaderTitleWrapper = styled.div`
  margin: 28px 0 21px;

  .center-align-vertical {
    button {
      margin: unset;
    }
    display: flex;
    align-items: center;
  }
`;

const TitleDiv = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 48px;
  text-align: left;
  flex-grow: 1;
  color: ${(props) => props.theme.colors.BlueNavy};
`;

const AllCapsButtonTertiary = styled(ButtonTertiary)`
  text-transform: uppercase;
`;

const BoxButtonsCreditStatementAndOrderList = styled.div<IProps>`
  background-color: #ffffff;
  width: ${(props) =>
    props.isScreenCreditStatementOrOrderList ? "100%" : "36%"};
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 15px;
`;

const ScreenCreditStatement = styled.div<{ haveBoxShadow?: boolean }>`
  background-color: rgb(242, 242, 243);
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-shadow: ${(props) => props.haveBoxShadow ? "rgba(0, 0, 0, 0.04) 0px 8px 10px" : ""} ;
`;

const BackButton = styled.div`
  margin: 15px;
`;

export {
  AllCapsButtonTertiary,
  BoxButtonsCreditStatementAndOrderList,
  HeaderTitleWrapper,
  TitleDiv,
  ScreenCreditStatement,
  BackButton,
};
