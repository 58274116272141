import { MsgValidation, YupHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ISaveOutOfStockPersonRequest } from "../../../models";

type checkoutPersonValidationType = {
  numberCrv?: string;
  securityCodeCrv?: string;
};
export const CheckOutPersonFormMoleculeValidation = ({
  numberCrv,
  securityCodeCrv,
}: checkoutPersonValidationType) => {
  const { t } = useTranslation();

  const msgRequired = t(MsgValidation.RequiredField);
  const msgInvalid = t(MsgValidation.Invalid);
  const msgEmail = t(MsgValidation.Email);
  const msgCrv = t("Invalid Crv");
  const msgSecurityCode = t("Invalid Security Code");

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfCnpjValid",
    YupHelper.isCpfCnpjValid
  );

  const validations = Yup.object<ISaveOutOfStockPersonRequest>({
    id: Yup.string().required(msgRequired),
    buyerDocument: Yup.string()
      .required(msgRequired)
      .isCpfCnpjValid(msgInvalid),
    buyerEmail: Yup.string().required(msgRequired).email(msgEmail),
    buyerName: Yup.string().required(msgRequired).max(100, msgInvalid),
    city: Yup.string().required(msgRequired).max(100, msgInvalid),
    docType: Yup.string().required(msgRequired),
    number: Yup.string().required(msgRequired).max(6, msgInvalid),
    saleValue: Yup.number()
      .min(1, t("SaleValueMustBeGreaterThanZero"))
      .required(msgRequired),
    numberCrvCheckout: Yup.string()
      .required(msgRequired)
      .min(12, msgCrv)
      .test("is-not-equal", t("CrvEqualMessage"), (value) => {
        return value !== numberCrv;
      }),
    securityCodeCrvCheckout: Yup.string()
      .required(msgRequired)
      .min(11, msgSecurityCode)
      .test("is-not-equal", t("CrvEqualMessage"), (value) => {
        return value !== securityCodeCrv;
      }),
    state: Yup.string().required(msgRequired).max(2, msgInvalid),
    street: Yup.string().required(msgRequired).max(200, msgInvalid),
    zipCode: Yup.string().required(msgRequired),
    neighborhood: Yup.string().required(msgRequired).max(100, msgInvalid),
    exitDate: Yup.date().required(msgRequired),
  });

  return validations;
};
