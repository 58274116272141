import { SpinnerCenteredAtom } from "c4u-web-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import {
  useLocalState,
  useRenave,
  useRenaveContext,
  useSessionContext,
} from "../../../hooks";
import {
  HeaderTitleMolecule,
  RenaveInformationMolecule,
} from "../../molecules";

export const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const { userContext } = useRenaveContext();
  const { showGenericErrorModal } = useSessionContext();
  const { getUpdateVehicles } = useRenave();
  const history = useHistory();
  const [continueOnPage, setcontinueOnPage] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lastUpdated, setLastUpdated] = useLocalState<Date | null>(
    "last-updated",
    null
  );
  const [isUpdatingVehicles, setIsUpdatingVehicles] = useState(true);
  useEffect(() => {
    async function callUpdateService() {
      try {
        const result = await getUpdateVehicles();
        if (result) {
          setLastUpdated(new Date());
        }
      } catch (error) {
        showGenericErrorModal(t("UpdateNotPossible"));
        console.log(error);
      } finally {
        setIsUpdatingVehicles(false);
      }
    }
    callUpdateService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userContext !== undefined && !isUpdatingVehicles) {
      userContext?.user.isMaster
        ? history.push(paths.registerDealership())
        : setcontinueOnPage(true);
    }
  }, [history, userContext, isUpdatingVehicles]);

  return (
    <>
      {continueOnPage && userContext !== undefined && !isUpdatingVehicles ? (
        <>
          <HeaderTitleMolecule title={t("Renave")} hideReturnButton />
          <hr className="mt-5" />
          <RenaveInformationMolecule />
        </>
      ) : (
        <div style={{ height: "80vh" }}>
          <SpinnerCenteredAtom />
        </div>
      )}
    </>
  );
};
